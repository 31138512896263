<template>
   <html>
      <body class="" style="background: #FFF">
        <Header />
        <MenuLateralMobile />
        <div class="h-screen grid grid-cols-6 grid-rows-12 grid-flow-col">
          <MenuLateral/>
            <div class="col-span-6 bg-white" style="padding:20px;">
              <AsignarEsquemaComisiones/>
            </div>
        </div>
      </body>
    </html>
</template>

<script>
import Header from "@/components/Administrative/Header/Header.vue";
import MenuLateralMobile from '@/components/MenuLateralMobile.vue';
import MenuLateral from '@/components/LateralMenu.vue';
import AsignarEsquemaComisiones from '@/components/CommissionScheme/AsignarEsquemaComisiones.vue';
export default {
    components:{
        Header,
        MenuLateralMobile,
        MenuLateral,
        AsignarEsquemaComisiones
    }
}
</script>
