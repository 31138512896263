<template>
    <Loading :isLoading="isLoading"/>
    <div>
        <div class="w-full">
            <p class="text-center font-bold text-3xl" style="color:#810042">Asignar esquema de Comisiones</p>
        </div>

         <div class="flex my-10 w-full lg:w-3/5">
            <label class="md:w-1/6 md:mt-2 md:mr-3">Buscar Asesor:</label>
            <div class=" w-full lg:w-3/4 ">
                <input type="text" class="shadow-md border-2 rounded-lg py-2 px-5 w-full  outline-none focus:border-cherryColor" 
                v-model="Vsearsh$.name.$model" placeholder="Nombre del Asesor"
                v-on:keyup.enter="SearshasSociate()"
                v-on:keyup.delete="searchAfterDelete()"
                id="input_search_assessor_name_assign_bonus"
                :maxlength="51"
                :class="{'invalid-form':Vsearsh$.name.$error, 'valid-form':!Vsearsh$.name.$invalid}">
                 <div v-for="error of Vsearsh$.name.$errors" :key="error.$message">
                    <div class="errors">{{ error.$message }}</div>
                </div>
            </div>
            <div>
                <button class="ml-5 p-2" style="background:#810042;border-radius:5px" id="icon_assessor_search_assign_bonus"
                    @click="SearshasSociate()" >
                    <img src="@/assets/lupa.svg" height="10" width="25" >
                </button>
            </div>
        </div>
        <div>
            <table class="width200">
                <thead>
                    <tr>
                        <th> <label class="w-full">Nombre del Asesor</label> </th>
                        <th>Asignar Esquema de Comisiones:</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(adviser,index) in _advisersResponse" :key="index" style="border-bottom:1px solid #810042"
                     :style="[(index%2==0)? {'background': '#FFF'}:{'background': '#efefef' }]">
                        <td class="pl-5">
                            <label class="w-full" :id="'label_assessor_name_assign_bonus_'+index">{{adviser.name}} {{adviser.middle_name}} {{adviser.paternal_name}} {{adviser.maternal_name}}</label>
                        </td>
                        <td>
                           <div class="flex">
                                <select class="shadow-md border-2 rounded-lg py-2 w-full md:w-3/5 px-5  mt-10 outline-none focus:border-cherryColor "  :id="'select_add_bonus_scheme_assign_bonus_'+index">
                                    <option value="" selected disabled>Agregar Nuevo Esquema de comisión. </option>
                                    <option :value="bobusSchema.id" v-for="(bobusSchema,index) in  activeBonusSchema " :key="index">{{bobusSchema.name}}</option>
                                </select>

                                <button class="ml-5 mt-10 " :id="'icon_add_bonus_scheme_assign_bonus:'+index" style="outline:0"
                                @click="assingBonusSheme(adviser,('select_add_bonus_scheme_assign_bonus_'+index))" >
                                    <img height="50" width="50" src="@/assets/Comisiones/add.svg">
                                </button>

                            </div>
                             <div v-if="adviser.catalog_comission_schema_info.length>0">
                                 <div class="w-3/5 mb-5" v-for="(catalog,index) in adviser.catalog_comission_schema_info" :key="index">
                                    <div class="text-gray-50 max-w-md p-4 mx-5 mt-4 sm:shadow-md sm::rounded-md sm:p-2 flex "
                                    :style="[(index%2==0)? {'background': '#CE7094'}:{'background': '#70AACE' }]">
                                    <!-- v-bind:class="{'bg-pink-600':index%2==0?true:false,'bg-blue-300':index%2==0?false:true}" -->
                                        <div class="w-5/6">
                                            <p class="mt-3 font-bold">{{catalog.name}}</p>
                                        </div>
                                        <div class="w-1/6 text-right text-4xl pr-5 pt-2">
                                            <button :id="'icon_delete_bonus_scheme_bonus_scheme_'+index" style="outline:0" @click="desactivecatalogStatus(catalog,adviser)">
                                                <img src="@/assets/Comisiones/delate_tag.svg" alt="">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="adviser.catalog_comission_schema_info.length==0" class="mt-5 italic ">
                               <p class="mb-2" style="color:#BAB2B2"> No se cuenta con Esquema de Comisión Asignado.</p>
                            </div>

                        </td>
                    </tr>
                   
                    <!-- <tr></tr> -->
                </tbody>
            </table>
             <div v-if="_advisersResponse.length==0">
                <div class="errors text-center text-3xl  " >Sin registros coincidentes  para mostrar.</div>
            </div>


            <div v-show="hidePagination">
                <Pagination ref="PaginationRef" :totalRowQuerry="totalRowQuerry" :maxQueryItems="10" v-on:change-pagination="getAssociateFiltred($event)" />
            </div>

        </div>
    </div>
</template>

<script>
import('@/assets/css/referredperson.css');
import('@/assets/css/stylesheet.css');
import('@/assets/css/referredperson.css');
import $ from "jquery";
import Loading from '@/components/Loading/VueLoading.vue';
import Pagination from "@/components/Forms/Pagination/Pagination.vue"
import CommissionScheme from "@/classes/CommissionScheme.js"
import { onBeforeMount, reactive, ref } from 'vue-demi';
import Swal from 'sweetalert2';
import useVuelidate from '@vuelidate/core';
import { helpers, requiredIf } from '@vuelidate/validators';
import {getTokenDecoden} from "@/helpers/tokenauth";
export default {
    name:"AsignarEsquemaComisiones",
    components:{
        Loading,
        Pagination
    },
    setup() {
        let isLoading=ref(true);
        let _class_CommissionScheme=new CommissionScheme();
        let activeBonusSchema=ref([]);
        let _advisersResponse=ref([]);

        let totalRowQuerry=ref(); //total de filas que hay en la consulta
        let maxQueryItems=ref(); //limite total de de elementos que buscara la consulta

        let rulesValidationInputSearsh=ref({
            name:""
        });

        let rulesvalidationInputSearsh=reactive({
            name:{
                required:helpers.withMessage("Es necesario ingresar un nombre de asesor valido para realizar una búsqueda.",requiredIf(()=>true)),
                maxlength:helpers.withMessage(()=>{return " El campo Buscar Asesor debe tener máximo 50 caracteres."},(value)=>{return 50>value.length}),
            }
        });

        let hidePagination=ref(true);

        let Vsearsh$=useVuelidate(rulesvalidationInputSearsh,rulesValidationInputSearsh);

        const PaginationRef=ref(null);
        //Metodo inicio
        onBeforeMount(()=>{
            getAllBonusSchema();
        });

        function searchAfterDelete(){
            if(rulesValidationInputSearsh.value.name.length==0){
                searshasSociate();
            }
        }

        async function searshasSociate(){
            const _searsh={
                    token_auth: sessionStorage.getItem("login"),
                    fullname:rulesValidationInputSearsh.value.name
                };
                const _response=await  _class_CommissionScheme.get(_searsh,"commission_schema/get_asociate_by_names");
                if(_response.status==500){
                    Swal.fire({
                        title: "Error",
                        html:_response.data.response.message,
                        icon: "error",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    }); 
                }
                if(_response.data.response.has_errors==false && _response.status==200 ){
                     if(_response.data.response.commission_schema_assigned.length==0){
                        Swal.fire({
                            title: "Aviso",
                            html:"Sin resultados para mostrar, ingrese un nombre de asesor valido.",
                            icon: "info",
                            confirmButtonText: "OK",
                            confirmButtonColor: '#FEB72B'
                        });
                        hidePagination.value=false;
                        _advisersResponse.value=[];
                        // getAssociateFilteredAfterofSearch(); 
                    }
                    else{
                        _advisersResponse.value=_response.data.response.commission_schema_assigned;
                        hidePagination.value=true;
                    }
                }
        }
        
        async function SearshasSociate(){
            isLoading.value=true;
            this.Vsearsh$.$validate();
            if(!this.Vsearsh$.$error){
                searshasSociate();
            }
            else{
             Swal.fire({
                    title: "Aviso",
                    html:"Sin resultados para mostrar, ingrese un nombre de asesor valido.",
                    icon: "info",
                    confirmButtonText: "OK",
                    confirmButtonColor: '#FEB72B'
                });
            }
            isLoading.value=false;
        } 

        async function getAssociateFilteredAfterofSearch(){
            const _response= await _class_CommissionScheme.get({
                    token_auth:sessionStorage.getItem("login"),
                    initial_row: 0,
                    final_row: 10
                },"foncabsa/commission_schema/get_associate_filtred");
                if(_response.data.response.has_errors){
                    let messages="";
                    response.data.errors.forEach(element => {
                        messages+="<strong>■ "+element+".</strong><br>"
                    });
                    Swal.fire({
                        title: "Aviso",
                        html:response.data.response.message+"<br>"+messages,
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    }); 
                }else{
                    hidePagination.value=true;
                    _advisersResponse.value=_response.data.response.associate_list.rows;
                    totalRowQuerry.value=await _response.data.response.associate_list.total_rows;
                    PaginationRef.value.setPagination(totalRowQuerry.value);
                }
        }


        async function getAllBonusSchema(){
            const _response= await _class_CommissionScheme.get({token_auth:sessionStorage.getItem("login")},"/commision_schema/get_active");
            if(_response.data.response.has_errors){
                let messages="";
                response.data.errors.forEach(element => {
                    messages+="<strong>■ "+element+".</strong><br>"
                });
                Swal.fire({
                    title: "Aviso",
                    html:response.data.response.message+"<br>"+messages,
                    icon: "info",
                    confirmButtonText: "OK",
                    confirmButtonColor: '#FEB72B'
                }); 
                setIdAttributeToAccepAndCancelButtonSwalFire('button_confirmation_assign_bonus', '');
            }else{
              activeBonusSchema.value=_response.data.response.commissio_schema;
            }
            isLoading.value=false;
        }

        async function getAssociateFiltred(value){
            const _response= await _class_CommissionScheme.get(value,"commission_schema/get_associate_filtred");
                if(_response.data.response.has_errors){
                    let messages="";
                    response.data.errors.forEach(element => {
                        messages+="<strong>■ "+element+".</strong><br>"
                    });
                    Swal.fire({
                        title: "Aviso",
                        html:response.data.response.message+"<br>"+messages,
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    }); 
                }else{
                    _advisersResponse.value=_response.data.response.associate_list.rows;
                    totalRowQuerry.value=await _response.data.response.associate_list.total_rows;
                    PaginationRef.value.setPagination(totalRowQuerry.value);
                }
        }

        function desactivecatalogStatus(element,adviser){
            Swal.fire({
                    title: "Aviso",
                    html: `¿Desea eliminar el <strong>${element.name}</strong> al asesor <strong>${adviser.name} ${adviser.middle_name} ${adviser.paternal_name} ${adviser.maternal_name} ? <strong>`,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Eliminar",
                    cancelButtonText: "Cancelar",
                    confirmButtonColor: '#FEB72B',
                    cancelButtonColor: '#FFFFFF',
            }).then(async (result) => {
                if (result.value) {   
                    await desactiveCatalogAPI(element,adviser);         
                }
            });
            setIdAttributeToAccepAndCancelButtonSwalFire('button_confirmation_delete_assign_bonus', 'button_confirmation_cancel_delete_assign_bonus');
        }

        async function desactiveCatalogAPI(element,adviser){
            const bodyReq={
                    "token_auth":sessionStorage.getItem("login"),
                    "catalog_sale_commission_schema_id":element.id.toString(),
                    "assigned_user_id":adviser.foncabsa_user_id
                }
            const _response= await _class_CommissionScheme.save(bodyReq,`/commission_schema/deactivate`);
                if(_response.status==500){
                    Swal.fire({
                        title: "Error",
                        html:`<strong>${_response.data.response.message}.</strong>`,
                        icon: "error",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                    return; 
                }
                if(_response.data.response.has_errors){
                    let messages="";
                    response.data.errors.forEach(element => {
                        messages+="<strong>■ "+element+".</strong><br>"
                    });
                    Swal.fire({
                        title: "Aviso",
                        html:_response.data.response.message+"<br>"+messages,
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    }); 
                }else{
                    Swal.fire({
                        title: "El esquema ha sido eliminado correctamente.",
                        //text: _response.data.response.message,
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                    PaginationRef.value.reloadTableQuery();
                }
                setIdAttributeToAccepAndCancelButtonSwalFire('button_confirmation_assign_bonus', '');

        }

        function  setIdAttributeToAccepAndCancelButtonSwalFire(idButtonConfirm, idButtonCancel) {
            $('.swal2-confirm').attr('id', idButtonConfirm);
            $('.swal2-cancel').attr('id', idButtonCancel);
        }

        async function assingBonusSheme(adviser,id){
            const _newScheme=document.getElementById(id).value;
            if(_newScheme!=""){
                const nameScheme=await activeBonusSchema.value.find(element=> {return element.id==_newScheme});
                Swal.fire({
                        title: "Aviso",
                        html: `¿Desea agregar el <strong>${nameScheme.name}</strong> al asesor <strong>${adviser.name} ${adviser.middle_name} ${adviser.paternal_name} ${adviser.maternal_name} ? <strong>`,
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Agregar",
                        cancelButtonText: "Cancelar",
                        confirmButtonColor: '#FEB72B',
                        cancelButtonColor: '#FFFFFF',
                }).then(async (result) => {
                    if (result.value) {   
                        AssignCommissionScheme(adviser,_newScheme,nameScheme);
                        document.getElementById(id).value="";
                    }
                });
                setIdAttributeToAccepAndCancelButtonSwalFire('button_confirmation_add_assign_bonus', 'button_confirmation_cancel_add_assign_bonus');
            }else{
                Swal.fire({
                    title: "Aviso",
                    html: `Favor de seleccionar un esquema para el asesor <br><strong>${adviser.name} ${adviser.middle_name==null?"":adviser.middle_name} ${adviser.paternal_name} ${adviser.maternal_name}. </strong>`,
                    icon: "info",
                    confirmButtonText: "OK",
                    confirmButtonColor: '#FEB72B'
                }); 
                document.getElementById(id).value="";
                setIdAttributeToAccepAndCancelButtonSwalFire('button_confirmation_assign_bonus', '');
            }
            
        } 

        async function AssignCommissionScheme(element,idSheme,nameScheme){
            const _sessionStorageItem=getTokenDecoden()
                const _assingCommissionScheme={
                    token_auth:sessionStorage.getItem("login"),
                    assigned_user_id:element.foncabsa_user_id,
                    catalog_sale_commission_schema_id:idSheme,
                    users_id:_sessionStorageItem.obj.id
                }
            const _response= await _class_CommissionScheme.save(_assingCommissionScheme,`/commission_schema/assign`);
                if(_response.status==500){
                    Swal.fire({
                        title: "Error",
                        html:`<strong>${_response.data.response.message}.</strong>`,
                        icon: "error",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                    setIdAttributeToAccepAndCancelButtonSwalFire('button_confirmation_assign_bonus', '');
                    return; 
                }
                if(_response.data.response.has_errors){
                    let messages="";
                    _response.data.errors.forEach(element => {
                        messages+="<strong>■ "+element+".</strong><br>"
                    });
                    Swal.fire({
                        title: "Aviso",
                        html: messages,
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    }); 
                    setIdAttributeToAccepAndCancelButtonSwalFire('button_confirmation_assign_bonus', '');
                }else{
                    Swal.fire({
                        title: `El esquema ${nameScheme.name} ha sido asignado correctamente.`,
                        //text: _response.data.response.message,
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                    setIdAttributeToAccepAndCancelButtonSwalFire('button_confirmation_assign_bonus', '');
                    PaginationRef.value.reloadTableQuery();
                }
        }


        
        //Metodos fin

        return{
            Vsearsh$,
            isLoading,
            activeBonusSchema,
            _advisersResponse,
            getAssociateFiltred,
            desactivecatalogStatus,
            assingBonusSheme,
            SearshasSociate,
            hidePagination,
            searchAfterDelete,
            //ref
            PaginationRef,

            //Props
            maxQueryItems,
            totalRowQuerry
            
        }
    },
}
</script>

<style>
#button_confirmation_cancel_delete_assign_bonus,
#button_confirmation_cancel_add_assign_bonus{
    color: #810042;
    border: 1px solid #810042;
}

#button_confirmation_cancel_delete_assign_bonus:hover,
#button_confirmation_cancel_add_assign_bonus:hover{
    color: white;
    background: #810042 !important;
    border: 1px solid #810042;
}
</style>

<style scoped >
.swal2-actions{
    direction: rtl;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  text-align: left;
  padding: 8px;
}

tr:nth-child(even){background-color: #ffffff}

th {
  background-color: #ffffff;

}

table.width200,table.rwd_auto {border:1px solid #810042;width:100%;margin:0 0 50px 0}
    .width200 th,.rwd_auto th {background:#ffffff;text-align:center;border:1px solid #810042;padding-top: 20px;padding-bottom: 20px;}
    /* .width200 td,.rwd_auto td {border:1px solid #810042;text-align:left;} */
    .width200 tr:last-child td, .rwd_auto tr:last-child td{border:50px}
/*
.rwd {width:100%;overflow:auto;}
    .rwd table.rwd_auto {width:auto;min-width:100%}
        .rwd_auto th,.rwd_auto td {white-space: nowrap;} */

.frame::-webkit-scrollbar {
  -webkit-appearance: none;
}

@media only screen and (max-width: 1024px)
{
    table.width200, .width200 thead, .width200 tbody, .width200 th, .width200 td, .width200 tr { display: block; }

    .width200 thead tr { position: absolute;top: -9999px;left: -9999px; }

    .width200 tr th:nth-child(5){display: none;}

    .width200 tr { border: 1px solid #810042;}

    .width200 td { border: none;border-bottom: 1px solid #810042; position: relative;padding-left: 40%;text-align:left }

    .width200 td:before {  position: absolute;top: 0px; left: 6px; width: 45%; padding-right: 10px; }

    .width200 td:nth-of-type(1):before { content: "Nombre del Asesor"; }
    .width200 td:nth-of-type(2):before { content: "Asignar Esquema de Comisiones:"; }

    .descarto {display:none;}
    .fontsize {font-size:10px}
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-width : 320px) and (max-width : 480px)
{
    .descarto {display:none;}
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1024px)
{
    .descarto {display:none;}
    .fontsize {font-size:10px}
    
}

@media only screen and (min-width: 1024px)
{
    .color-cherry-border {border:2px solid #810042}
}

/* #icon_add_bonus_scheme_assign_bonus {
    margin: 20px ;
    border:1px solid #810042;
} */

@media (max-width: 300px) {
  .pagination-items {
    height: 20px;
    width: 30px;
  }
}
</style>